






import { Component, Vue } from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import ShipmentBase from '@/modules/shipments/views/detail/components/ShipmentBase.vue'

@Component({
  components: {
    InfoCard,
    ShipmentBase
  }
})
export default class ScanTradelane extends Vue {
  created() {
    this.$store.commit('isOnConsignment', true)
  }
}
